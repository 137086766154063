import React, { useState, useEffect, createContext } from "react";
import common from "@root/translations/common.json";

// Create contexts for each language
const enContext = require.context('@root/translations/en', true, /\.json$/);
const frContext = require.context('@root/translations/fr', true, /\.json$/);
const nlContext = require.context('@root/translations/nl', true, /\.json$/);

// Helper function to import all JSON files in a context
function importAll(context) {
  let phrases = {};
  context.keys().forEach((key) => {
    phrases = { ...phrases, ...context(key) };
  });
  return phrases;
}

// Import all JSON files from each context
const en = importAll(enContext);
const fr = importAll(frContext);
const nl = importAll(nlContext);

const translations = {
  en: { ...en, ...common },
  fr: { ...fr, ...common },
  nl: { ...nl, ...common },
};

/**
 * A context for managing the language and translations of the application.
 * Use this context to provide the language and phrases to components that need translations.
 */
const LanguageContext = createContext();

/**
 * @typedef {Object} LanguageProviderProps
 * @property {React.ReactNode} children - The child components to be wrapped by the LanguageProvider.
 */

/**
 * A custom LanguageProvider component that manages language and translations for the application.
 * It sets the language based on the subdomain and provides the translated phrases to child components.
 * 
 * @example
 * // Wrap your application with the LanguageProvider
 * <LanguageProvider>
 *   <App />
 * </LanguageProvider>
 * 
 * @param {LanguageProviderProps} props - The properties passed to the component.
 */

function LanguageProvider(props) {
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    const subdomain = window.location.hostname.split(".")[0];

    if (Object.keys(translations).includes(subdomain)) {
      setLanguage(subdomain);
    } else {
      console.warn(`Language '${subdomain}' not found, defaulting to 'en'.`);
      setLanguage("en");
    }
  }, []);

  const phrases = {
    ...translations[language],
  };

  return (
    <LanguageContext.Provider value={{ language, phrases }}>
      {props.children}
    </LanguageContext.Provider>
  );
}
export function getPhrase(key, phrases, fallback) {
  return phrases[key] || fallback || key;
}

export { LanguageContext, LanguageProvider };
