const config = {
  hostname: "laizo.co",
  firebase: "worldey-59acb",
  headerButtons: ["Home", "Experience", "News"],
  DecorationsActive: false,
};

module.exports = {
  config,
};
