import React, { createContext, useEffect, useState, useMemo } from "react";
import { config } from "@root/config";

export const RandomThemeContext = createContext(null);

const themeCount = 11;

function checkConnectionType() {
  if (navigator.connection) {
    return navigator.connection.effectiveType;
  }
  return "unknown";
};

function checkDeviceType() {
  return window.matchMedia("(max-width: 768px)").matches;
};

const RandomThemeProvider = React.memo(({ children }) => {
  const [randomThemeIndex, setRandomThemeIndex] = useState(null);

  const videoPath = useMemo(() => randomThemeIndex
    ? `https://storage.googleapis.com/${config.firebase}.appspot.com/planet_videos/planet${randomThemeIndex}.webm`
    : null, [randomThemeIndex]);

  const alternateVideoPath = useMemo(() => randomThemeIndex
    ? `/videos/planets/planet${randomThemeIndex}.webm`
    : null, [randomThemeIndex]);

  const imagePath = useMemo(() => randomThemeIndex
    ? `https://storage.googleapis.com/${config.firebase}.appspot.com/planet_images/planet${randomThemeIndex}.webp`
    : null, [randomThemeIndex]);

  const alternateImagePath = useMemo(() => randomThemeIndex
    ? `/images/planets/planet${randomThemeIndex}.webp`
    : null, [randomThemeIndex]);

    useEffect(() => {
      const index = Math.floor(Math.random() * themeCount) + 1;
      setRandomThemeIndex(index);
  
      import(`./tokens/tokens${index}.css`).then((module) => {
        const style = document.createElement("style");
        style.textContent = module.default;
        document.head.appendChild(style);
      });
  
    }, []);
  
    useEffect(() => {
      if (randomThemeIndex) {
        const createAndAppendLink = (href, type) => {
          const link = document.createElement("link");
          link.rel = "preload";
          link.href = href;
          link.as = type;
          link.setAttribute("fetchpriority", "high");
          if (type === "image") {
            link.importance = 'high';
          }
          document.head.appendChild(link);
        };
  
        if (checkDeviceType() || ["slow-2g", "2g"].includes(checkConnectionType())) {
          createAndAppendLink(imagePath, "image");
        } else {
          createAndAppendLink(videoPath, "fetch");
        }
      }
    }, [randomThemeIndex, videoPath, imagePath]);

  return (
    <RandomThemeContext.Provider
      value={{ randomThemeIndex, videoPath, alternateVideoPath, imagePath, alternateImagePath }}
    >
      {children}
    </RandomThemeContext.Provider>
  );
});

export default RandomThemeProvider;
