import React, { lazy } from "react";
import ReactDOM from "react-dom/client";
import { LanguageProvider } from "@template/Utils/LanguageProvider";

import "material-icons/iconfont/material-icons.css";
import "@template/index.css";
import "@template/Theme/theme.css";

import reportWebVitals from "@template/reportWebVitals";
import RandomThemeProvider from "@template/Theme/randomTheme";

if ("serviceWorker" in navigator) {
  window.addEventListener("load", function () {
    navigator.serviceWorker.register("/serviceWorker.js").then(
      function (registration) {
        console.log(
          "ServiceWorker registration successful with scope: ",
          registration.scope
        );
      },
      function (err) {
        console.log("ServiceWorker registration failed: ", err);
      }
    );
  });
}

const App = lazy(() => import("@template/App"));

/**
 * Entry point for the React application.
 *
 * This file sets up the React application, wraps the main App component with the LanguageProvider and
 * React.StrictMode components, and renders it into the root DOM element. It also calls reportWebVitals()
 * for performance analysis.
 */
const root = ReactDOM.createRoot(document.getElementById("root"));
const defaultLanguage = "en"; //TODO

root.render(
  <RandomThemeProvider>
    <LanguageProvider defaultLanguage={defaultLanguage}>
      <App />
    </LanguageProvider>
  </RandomThemeProvider>
);

reportWebVitals();
